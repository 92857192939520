
































































































































































































import { ModalComponentBase } from '../../../../../shared/component-base';
import {
	CreateOrUpdateEducationBackgroundInput,
	DataItemDetailServiceProxy,
	EducationBackgroundEditDto,
	EducationBackgroundServiceProxy,
} from '@/shared';
import Dic from '@/shared/utils/dic';
import zh_CN from 'ant-design-vue/lib/locale-provider/zh_CN';

export default {
	name: 'create-or-edit-education-background',
	mixins: [ModalComponentBase],
	data() {
		return {
			form: this.$form.createForm(this),
			spinning: false,
			zh_CN,
			id: undefined,
			UserId: undefined,
			entity: new EducationBackgroundEditDto(),
			//教育类型
			EduTypeList: [],
			//最高学历
			IsHighestEducation: [],
			//是否第一学历
			IsFirstEdu: [],
			//是否211/985
			IsNProject: [],
			//学历
			Education: [],
			//学位
			Degrees: [],
			_educationBackgroundServiceProxy: '',
		};
	},
	async created() {
		this.fullData();

		this.EduTypeList = await Dic.getInstance().getDicAsync('EducationType');
		this.IsHighestEducation = await Dic.getInstance().getDicAsync('YesOrNo');
		this.Education = await Dic.getInstance().getDicAsync('Education');
		this.Degrees = await Dic.getInstance().getDicAsync('Degrees');
		this.IsFirstEdu = this.IsHighestEducation;
		this.IsNProject = this.IsHighestEducation;
		//默认选择否
		this.entity.isNProjectId = this.IsNProject.find((item) => item.itemDetailName == '否').id;
		this._educationBackgroundServiceProxy = new EducationBackgroundServiceProxy(this.$apiUrl, this.$api);
		if (this.id) {
			this.getData();
		}
	},
	methods: {
		getData() {
			this.spinning = true;
			this._educationBackgroundServiceProxy
				.getForEdit(this.id)
				.finally(() => {
					this.spinning = false;
				})
				.then((res) => {
					if (res) {
						this.entity = res.educationBackground;
					}
				});
		},
		//入学时间
		StartDateonChange(date) {
			this.entity.startDate = date;
		},
		//毕业时间
		EndDateonChange(date) {
			this.entity.endDate = date;
		},
		save() {
			if (!this.UserId) {
				return abp.message.warn('用户未知');
			}
			if (!this.entity.startDate) {
				return abp.message.warn('请选择入学时间');
			}
			if (!this.entity.endDate) {
				return abp.message.warn('请选择毕业时间');
			}
			if (!this.entity.schoolName) {
				return abp.message.warn('请输入学校名称');
			}
			if (!this.entity.professional) {
				return abp.message.warn('请输入专业');
			}
			if (!this.entity.eduTypeCode) {
				return abp.message.warn('请选择教育类型');
			}
			if (!this.entity.educationsCode) {
				return abp.message.warn('请选择学历');
			}
			if (!this.entity.isHighestEducationCode) {
				return abp.message.warn('请选择最高学历');
			}
			if (!this.entity.isFirstEduCode) {
				return abp.message.warn('请选择是否第一学历');
			}
			if (!this.entity.isNProjectCode) {
				return abp.message.warn('请选择是否211/985');
			}
			const input = new CreateOrUpdateEducationBackgroundInput();
			this.entity.empId = this.UserId;
			input.educationBackground = EducationBackgroundEditDto.fromJS(this.entity);
			this.spinning = true;
			this._educationBackgroundServiceProxy
				.createOrUpdate(input)
				.finally(() => {
					this.spinning = false;
				})
				.then((res) => {
					this.$notification['success']({
						message: this.l('SavedSuccessfully'),
					});
					this.success(true);
				});
		},
	},
};
