

































































































































import { ModalComponentBase } from '../../../../../shared/component-base';
import { CreateOrUpdateWorkExperienceInput, WorkExperienceEditDto, WorkExperienceServiceProxy } from '@/shared';
import Reg from '@/shared/utils/reg';
import UploadFile from '@/components/tools/UploadFile.vue';
import zh_CN from 'ant-design-vue/lib/locale-provider/zh_CN';

export default {
	name: 'create-or-edit-work-experience',
	mixins: [ModalComponentBase],
	components: { UploadFile },
	data() {
		return {
			form: this.$form.createForm(this),
			spinning: false,
			zh_CN,
			id: undefined,
			UserId: undefined,
			entity: new WorkExperienceEditDto(),
			_WorkExperienceServiceProxy: '',
		};
	},
	created() {
		this.fullData();
		this._WorkExperienceServiceProxy = new WorkExperienceServiceProxy(this.$apiUrl, this.$api);
		if (this.id) {
			this.getData();
		}
	},
	methods: {
		getData() {
			this.spinning = true;
			this._WorkExperienceServiceProxy
				.getForEdit(this.id)
				.finally(() => {
					this.spinning = false;
				})
				.then((res) => {
					if (res) {
						this.entity = res.workExperience;
					}
				});
		},
		//入学时间
		StartDateonChange(date) {
			this.entity.startDate = date;
		},
		//毕业时间
		EndDateonChange(date) {
			this.entity.endDate = date;
		},
		save() {
			if (!this.UserId) {
				return abp.message.warn('用户未知');
			}
			if (!this.entity.companyName) {
				return abp.message.warn('请输入所在公司');
			}
			if (!this.entity.startDate) {
				return abp.message.warn('请选择开始时间');
			}
			if (!this.entity.endDate) {
				return abp.message.warn('请选择结束时间');
			}
			if (!this.entity.salary) {
				this.entity.salary = 0;
			}
			if (this.entity.certificateEmplTel && !Reg.getInstance().RegMobile(this.entity.certificateEmplTel)) {
				return abp.message.warn('请输入有效的证明人电话');
			}
			const input = new CreateOrUpdateWorkExperienceInput();
			this.entity.empId = this.UserId;
			input.workExperience = WorkExperienceEditDto.fromJS(this.entity);
			this.spinning = true;
			this._WorkExperienceServiceProxy
				.createOrUpdate(input)
				.finally(() => {
					this.spinning = true;
				})
				.then((res) => {
					this.$notification['success']({
						message: this.l('SavedSuccessfully'),
					});
					this.success(true);
				});
		},
	},
};
