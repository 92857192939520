
































































































































































































































































































































































































































































































































































































































import { AppComponentBase } from '@/shared/component-base';
import {
	OrgPostGradeServiceProxy,
	OrgPostServiceProxy,
	ProfileServiceProxy,
	UserServiceProxy,
	UserEditDto,
	EmployeeServiceProxy,
} from '@/shared/service-proxies';
import Dic from '@/shared/utils/dic';
import moment from 'moment';
import ObjectUtils from '@/shared/utils/ObjectUtils';
import { AppConsts } from '@/abpPro/AppConsts';
import ImageUtils from '@/shared/utils/image-utils';
import $ from 'jquery';
import Reg from '@/shared/utils/reg';
import zh_CN from 'ant-design-vue/lib/locale-provider/zh_CN';

let svg = 10 / 12;
export default {
	name: 'create-or-edit-view-user',
	mixins: [AppComponentBase],
	props: {
		id: {
			type: Number,
			default: undefined,
		},
		companyId: {
			type: Number,
			default: undefined,
		},
	},
	data() {
		return {
			loading: false,
			zh_CN,
			previewVisible: false,
			_userServiceProxy: null,
			_orgPostServiceProxy: null,
			_orgPostGradeServiceProxy: null,
			_profileServiceProxy: null,

			user: new UserEditDto(),

			//岗位
			orgPostTreeData: [],
			orgPostTree: [],

			//数据字典相关变量
			//民族
			dicNationList: [],
			//劳务公司
			dicPersonalServiceList: [],
			//户口性质
			dicResidenceTypeList: [],
			//招聘来源
			dicRecruitmentSourceList: [],
			//学历
			dicEducationList: [],
			//性别字典列表
			dicGenderList: [],
			//婚姻状况字典列表
			dicMaritalStatus: [],
			//政治面貌字典列表
			dicPoliticsStatus: [],
			//员工类型字典列表
			dicEmployeeType: [],
			//员工状态字典列表
			dicEmployeeState: [],

			//岗位等级
			orgGradeList: [],
			list: [],
			// 头像相关变量
			uploadPictureUrl: AppConsts.remoteServiceBaseUrl + '/Profile/UploadProfilePictureReturnFileId',
			fileList: {
				//头像
				head: [],
				//身份证人像面
				faceImg: [],
				//身份证国徽面
				emblemImg: [],
				//证件照
				photo: [],
			},

			uploadHeaders: {
				//身份证人像面
				faceImgHeaders: {
					Authorization: 'Bearer ' + abp.auth.getToken(),
					type: 'faceImg',
				},
				//身份证国徽面
				emblemImgHeaders: {
					Authorization: 'Bearer ' + abp.auth.getToken(),
					type: 'emblemImg',
				},
				//证件照
				photoHeaders: {
					Authorization: 'Bearer ' + abp.auth.getToken(),
					type: 'photo',
				},
			},
			//头像
			headPath: `${AppConsts.remoteServiceBaseUrl}/${AppConsts.localization.defaultLocalizationSourceName}/UserPicture/${abp.session.tenantId}/`,
			//用户相关文件
			userFilePath: `${AppConsts.remoteServiceBaseUrl}/${AppConsts.localization.defaultLocalizationSourceName}/UserPicture/File/${abp.session.tenantId}/`,
			workAge: '',
			Age: '',
			//预览的图片
			previewImage: '',
		};
	},
	computed: {
		//下面两个字段是通过计算来显示用的，不需要传到后台
		getAge() {
			return this.user.birthday ? moment().diff(this.user.birthday, 'years') : 0;
		},
		getWorkAge() {
			return this.user.hireDate ? moment().diff(this.user.hireDate, 'years') : 0;
		},
	},
	created() {
		// Object.assign(this.uploadHeaders, {
		// 	Authorization: "Bearer " + abp.auth.getToken()
		// });
		this._employeeServiceProxy = new EmployeeServiceProxy(this.$apiUrl, this.$api);
		this._userServiceProxy = new UserServiceProxy(this.$apiUrl, this.$api);
		this._orgPostServiceProxy = new OrgPostServiceProxy(this.$apiUrl, this.$api);
		this._orgPostGradeServiceProxy = new OrgPostGradeServiceProxy(this.$apiUrl, this.$api);
		this._profileServiceProxy = new ProfileServiceProxy(this.$apiUrl, this.$api);
	},
	async mounted() {
		try {
			this.loading = true;
			this.user.cardIsLongTerm = false;
			this.getOrgPostTreeData();
			this.dicNationList = await Dic.getInstance().getDicAsync('Nation');
			this.dicPersonalServiceList = await Dic.getInstance().getDicAsync('PersonalService');
			this.dicResidenceTypeList = await Dic.getInstance().getDicAsync('ResidenceType');
			this.dicRecruitmentSourceList = await Dic.getInstance().getDicAsync('RecruitmentSource');
			this.dicEducationList = await Dic.getInstance().getDicAsync('Education');
			this.dicGenderList = await Dic.getInstance().getDicAsync('Gender');
			this.dicMaritalStatus = await Dic.getInstance().getDicAsync('MaritalStatus');
			this.dicPoliticsStatus = await Dic.getInstance().getDicAsync('PoliticsStatus');
			this.dicEmployeeType = await Dic.getInstance().getDicAsync('EmpType');
			this.dicEmployeeState = await Dic.getInstance().getDicAsync('EmpState');

			this.user = await this.getForEdit();
			this.user = ObjectUtils.initDto(this.user);
			if (!this.user.cardIsLongTerm) {
				this.user.cardIsLongTerm = false;
			}
			if (this.user.hireDate) {
				let diff = this.user.hireDate ? moment().diff(moment(this.user.hireDate), 'months') : 0;
				this.workAge = ((diff * svg) / 10).toFixed(1);
			}
			if (this.user.birthday) {
				this.Age = this.user.birthday ? moment().diff(this.user.birthday, 'years') : 0;
			}
			this.pictureInit();

			this.orgGradeList = await this.getPostGradeListByPostId(this.user.orgPostId);
		} catch (e) {
			console.error(e);
		} finally {
			this.loading = false;
		}
	},
	methods: {
		handleSubmit() {
			if (!Reg.getInstance().RegCard(this.user.identityCard)) {
				return abp.message.warn('请输入有效的身份证号');
			}
			if (this.user.cardIsLongTerm) {
				this.user.identityValidity = undefined;
			}
			this.loading = true;
			this._userServiceProxy
				.userInfoUpdate(this.user)
				.finally(() => {
					this.loading = false;
					this.$emit('onSaveComplete', '');
				})
				.then((res) => {
					this.$notification['success']({
						message: this.l('SavedSuccessfully'),
					});
				});
		},
		getForEdit() {
			return new Promise((resolve) => {
				this._userServiceProxy.GetForEdit(this.id).then((res) => {
					resolve(res);
				});
			});
		},
		/**
		 * 初始化图片
		 */
		pictureInit() {
			// 设置头像
			if (this.user.profilePictureId && this.user.userPictureType) {
				// await this.getProfilePicture(this.user.profilePictureId);
				this.fileList.head = [
					{
						uid: -1,
						name: this.user.profilePictureId,
						status: 'done',
						url: this.headPath + this.user.profilePictureId + this.user.userPictureType,
					},
				];
			}
			// 设置身份证人像面
			if (this.user.identityFaceImg) {
				// await this.getProfilePicture(this.user.profilePictureId);
				this.fileList.faceImg = [
					{
						uid: -1,
						name: this.user.identityFaceImg,
						status: 'done',
						url: this.userFilePath + this.user.identityFaceImg,
					},
				];
			}
			// 设置身份证人像面
			if (this.user.identityEmblemImg) {
				// await this.getProfilePicture(this.user.profilePictureId);
				this.fileList.emblemImg = [
					{
						uid: -1,
						name: this.user.identityEmblemImg,
						status: 'done',
						url: this.userFilePath + this.user.identityEmblemImg,
					},
				];
			}
			// 设置身份证人像面
			if (this.user.idPhoto) {
				// await this.getProfilePicture(this.user.profilePictureId);
				this.fileList.photo = [
					{
						uid: -1,
						name: this.user.idPhoto,
						status: 'done',
						url: this.userFilePath + this.user.idPhoto,
					},
				];
			}
		},
		/**
		 * 选择岗位触发
		 */
		async selectOrgPostTree(e) {
			this.user.orgPostId = e;
			//每次选择初始化两个岗位等级选择框
			this.user.probationGradeId = undefined;
			this.user.regularGradeId = undefined;
			//获取岗位等级列表
			this.orgGradeList = await this.getPostGradeListByPostId(e);
		},
		/**
		 * 获取岗位等级列表
		 */
		getPostGradeListByPostId(e) {
			return new Promise((resolve) => {
				this._orgPostGradeServiceProxy
					.getPostGradeListByPostId(e)
					.then((res) => {
						resolve(res);
					})
					.catch(() => {
						resolve([]);
					});
			});
		},
		/**
		 * 获取岗位
		 */
		getOrgPostTreeData() {
			this.orgPostTreeData = [];
			this._orgPostServiceProxy.getListByCompanyId(this.companyId).then((res) => {
				this.orgPostTree = res;
				this.orgPostTree.forEach((item) => {
					if (!item.parentId) {
						let obj = {
							key: item.id,
							value: item.id.toString(),
							title: item.name,
						};
						this.orgPostTreeData.push(obj);
					}
				});
				this.initOrgPostTree(this.orgPostTreeData);
			});
		},
		/**
		 * 初始化树形结构数据格式
		 */
		initOrgPostTree(list) {
			list.forEach((v) => {
				//遍历父级数据，加载子集=
				v.children = []; //创建空的子集
				this.orgPostTree.forEach((m) => {
					if (m.parentId == v.key) {
						//根据主键查子集数据
						var obj = {
							key: m.id,
							value: m.id.toString(),
							title: m.name,
						};
						v.children.push(obj); //放进父级的集合中
						this.initOrgPostTree(v.children); //递归查询子集的集合
					}
				});
				if (v.children.length == 0) {
					//校验如何数据没有子集，删除子集参数
					delete v.children;
				}
			});
		},
		onChange(e) {
			if (e) {
				let old = moment(e);
				let diff = moment().diff(moment(old), 'months');
				this.workAge = ((diff * svg) / 10).toFixed(1);
			}
		},
		/**
		 *
		 */
		onbirthdayChange(e) {
			if (e) {
				this.Age = moment().diff(e, 'years');
			}
		},
		beforeUpload(file) {
			const isJPG = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/gif';
			if (!isJPG) {
				abp.message.error(this.l('OnlySupportPictureFile'));
			}
			const isLtXM = file.size / 1024 / 1024 < 2;
			if (!isLtXM) {
				abp.message.error(this.l('ProfilePicture_Warn_SizeLimit', 2));
			}
			return isJPG && isLtXM;
		},
		handleChange(e, type) {
			switch (type) {
				case 'faceImg':
					if (e.file.status == 'done') {
						this.user.identityFaceImg = e.file.response.result.fileName;
					}
					this.fileList.faceImg = e.fileList;
					break;
				case 'emblemImg':
					if (e.file.status == 'done') {
						this.user.identityEmblemImg = e.file.response.result.fileName;
					}
					this.fileList.emblemImg = e.fileList;
					break;
				case 'photo':
					if (e.file.status == 'done') {
						this.user.idPhoto = e.file.response.result.fileName;
					}
					this.fileList.photo = e.fileList;
					break;
			}
		},
		async handlePreview(file) {
			// if (!file.url && !file.preview) {
			// 	file.preview = await ImageUtils.getBase64(file.originFileObj);
			// }
			// let url = file.url || file.preview;
			// ImageUtils.showFullImage(url);
			if (!file.url && !file.preview) {
				file.preview = await ImageUtils.getBase64(file.originFileObj);
			}
			this.previewImage = file.url || file.preview;
			this.previewVisible = true;
		},
		/**
		 * 通过头像Id获取头像
		 * @param profilePictureId 头像Id
		 */
		getProfilePicture(profilePictureId) {
			return new Promise((resolve) => {
				this._profileServiceProxy.getProfilePictureById(profilePictureId).then((result) => {
					if (result && result.profilePicture) {
						let profilePreviewImage = 'data:image/jpeg;base64,' + result.profilePicture;
						// 把图像加到头像列表 显示
						this.fileList = [
							{
								uid: -1,
								name: profilePictureId,
								status: 'done',
								url: profilePreviewImage,
							},
						];
					}
					resolve(1);
				});
			});
		},
	},
};
