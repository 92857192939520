













































































































import { ModalComponentBase } from '../../../../../shared/component-base';
import { CreateOrUpdateSocialRelationInput, SocialRelationEditDto, SocialRelationServiceProxy } from '@/shared';
import Dic from '@/shared/utils/dic';
import Reg from '@/shared/utils/reg';
import zh_CN from 'ant-design-vue/lib/locale-provider/zh_CN';

export default {
	name: 'create-or-edit-social-relation',
	mixins: [ModalComponentBase],
	data() {
		return {
			form: this.$form.createForm(this),
			zh_CN,
			id: undefined,
			spinning: false,
			UserId: undefined,
			entity: new SocialRelationEditDto(),
			IsUrgentEmplList: [],
			_SocialRelationServiceProxy: '',
		};
	},
	async created() {
		this.fullData();
		this.IsUrgentEmplList = await Dic.getInstance().getDicAsync('YesOrNo');
		this._SocialRelationServiceProxy = new SocialRelationServiceProxy(this.$apiUrl, this.$api);
		if (this.id) {
			this.getData();
		}
	},
	mounted() {},
	methods: {
		getData() {
			this.spinning = true;
			this._SocialRelationServiceProxy
				.getForEdit(this.id)
				.finally(() => {
					this.spinning = false;
				})
				.then((res) => {
					if (res) {
						this.entity = res.socialRelation;
					}
				});
		},
		save() {
			if (!this.UserId) {
				return abp.message.warn('用户未知');
			}
			if (!this.entity.name) {
				return abp.message.warn('请输入姓名');
			}
			if (!this.entity.relation) {
				return abp.message.warn('请输入关系');
			}
			// if (this.entity.phone && !Reg.getInstance().RegMobile(this.entity.phone)) {
			//     return abp.message.warn('请输入有效的联系电话');
			// }
			const input = new CreateOrUpdateSocialRelationInput();
			this.entity.empId = this.UserId;
			input.socialRelation = SocialRelationEditDto.fromJS(this.entity);
			this.spinning = true;
			this._SocialRelationServiceProxy
				.createOrUpdate(input)
				.finally(() => {
					this.spinning = false;
				})
				.then((res) => {
					this.$notification['success']({
						message: this.l('SavedSuccessfully'),
					});
					this.success(true);
				});
		},
	},
};
